<template>
  <div>
    
    <!-- If user is not yet signed in -->
    <div v-if="
      authState === 'signin' ||
      authState === 'signedout' ||
      authState === 'signup' ||
      authState === 'confirmSignIn' ||
      authState === 'confirmSignUp' ||
      authState === 'forgotpassword' ||
      verifiedBetaUser === undefined ||
      acceptedUserTerms === undefined ||
      verifiedBetaUser === false ||
      acceptedUserTerms === false ||
      coins === undefined ||
      userSettings === undefined"
      class="p-grid">
      <div class="p-col">
        <div>
          <!-- <img src="../assets/logo.svg" width="200" height="200" /> -->
          <CoinFeed3D/>
          <div v-if="authState === 'signin'">
            <a>
              Real-time tracking, analysis and notifications for crypto assets.
            </a>
          </div>
          <div id="loadingDiv" v-if="
            authState === 'signedin' && verifiedBetaUser === undefined ||
            authState === 'signedin' && verifiedBetaUser === true && acceptedUserTerms === undefined ||
            authState === 'signedin' && coins === undefined ||
            authState === 'signedin' && userSettings === undefined"
          >
            <a id="loadingStatus">{{loadingStatus}}</a>
            <br/><br/>
            <ProgressBar v-show="loading" mode="indeterminate"/>
          </div>
          <br />
        </div>
        <amplify-authenticator id="authenticator" username-alias="email">
          <amplify-sign-in slot="sign-in" username-alias="email">
          </amplify-sign-in>
          <amplify-sign-up
            slot="sign-up"
            username-alias="email"
            :formFields.prop="formFields"
          ></amplify-sign-up>
        </amplify-authenticator>
      </div>
    </div>

    <!-- If user is signed in and is not a beta user -->
    <div v-if="authState === 'signedin' && verifiedBetaUser === false">
      <p id="nonBetaUser">
        Thank you for signing up for coinfeed.  We are currently operating under an invite-only alpha program. If you are interested in participating please reach out to:
        <a href="mailto: admin@coinfeed.org" target="_blank">
        admin@coinfeed.org
        </a>.
      </p>
      <br /> <br />
      <a id="signout" @click="this.signout">signout</a>
    </div>

    <!-- If user is signed in, verified as beta user, and has not accepted terms -->
    <div id="terms" v-if="authState === 'signedin' && verifiedBetaUser === true && acceptedUserTerms === false">
      <CoinfeedTerms :user="user" @updatingLoadingStatus="updatingLoadingStatus" @updateUserAgreementStatus="updateUserAgreementStatus" @signout="signout"/>
      <br/><br/>
      <ProgressBar v-show="loading" mode="indeterminate"/>
    </div>

    <!-- If user is signed in, verified as beta user, accepted terms, and coins are loaded -->
    <div v-if="authState === 'signedin' && verifiedBetaUser === true && acceptedUserTerms === true && coins && userSettings">
      <NavBar :coins="coins" @signout="signout"/>
      <router-view
        :user="user"
        :coins="coins"
        :marquee="marquee"
        :userSettings="userSettings"
        @updateUserSettings="updateUserSettings"
      />
      <footer id="footer">
        <div>
          <b>
            COINFEED IS NOT FINANCIAL ADVICE AND IS INTENDED FOR INFORMATIONAL PURPOSES ONLY.
          </b>
          <br /><br />
          <a class="socialIcons" href="https://twitter.com/coinfeed_org" target="_blank" rel="noopener noreferrer">
            <i class="pi pi-twitter" style="font-size: 2rem"/>
          </a>&nbsp;
          <a class="socialIcons" href="https://discord.gg/NKkgSgrpfA" target="_blank" rel="noopener noreferrer">
            <i class="pi pi-discord" style="font-size: 2rem"/>
          </a>&nbsp;
          <a class="socialIcons" href="https://www.instagram.com/coinfeed_org" target="_blank" rel="noopener noreferrer">
            <img src="../assets/instagram_icon_small.png" />
          </a>
          <br />
          <a href="mailto: admin@coinfeed.org" target="_blank">&copy; 2021 alfarok llc</a>
        </div>
      </footer>
    </div>

  </div>
</template>

<script>
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import ProgressBar from "primevue/progressbar";
import Stream from "./../utilities/tvjs-stream.js";
import NavBar from "./NavBar.vue";
import CoinfeedTerms from "./CoinfeedTerms.vue";
import CoinFeed3D from "./branding/CoinFeed3D.vue";

const axios = require("axios");
const VUE_APP_AUTHENTICATED_API_URL = process.env.VUE_APP_AUTHENTICATED_API_URL;
const userDefaults = require('./../utilities/userDefaults.json');


export default {
  name: "Home",
  components: {
    ProgressBar,
    NavBar,
    CoinfeedTerms,
    CoinFeed3D,
  },
  data() {
    return {
      env: process.env.VUE_APP_DEPLOYMENT_ENVIRONMENT,
      authState: undefined,
      loadingStatus: undefined,
      user: undefined,
      verifiedBetaUser: undefined,
      acceptedUserTerms: undefined,
      unsubscribeAuth: undefined,
      formFields: [
        {
          type: "given_name",
          label: "First Name",
          placeholder: "Enter your first name",
          required: true,
        },
        {
          type: "family_name",
          label: "Last Name",
          placeholder: "Enter your last name",
          required: true,
        },
        {
          type: "phone_number",
          label: "Phone Number",
          placeholder: "XXX-XXX-XXXX",
          required: true,
        },
        {
          type: "email",
          label: "Email (Username)",
          placeholder: "Enter email address",
          required: true,
        },
        {
          type: "password",
          label: "Password",
          placeholder: "Enter a password",
          required: true,
        },
      ],
      coins: undefined,
      userSettings: undefined,
      marketCaps: [],
      marquee: [],
      loading: false,
    };
  },
  methods: {
    async signout() {
      try {
        await Auth.signOut();
        this.verifiedBetaUser = undefined;
        this.acceptedUserTerms = undefined;
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
    async getMarketCapData(user) {
      const token = user.signInUserSession.idToken;
      const url = `${VUE_APP_AUTHENTICATED_API_URL}readMarketCap`;
      const headers = { headers: { Authorization: token.jwtToken } };

      await axios
        .get(url, headers)
        .then((response) => {
          this.marketCaps = response.data.data.coins;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.message);
        });
    },
    async getCoinData(user) {
      this.loading = true;
      const token = user.signInUserSession.idToken;
      const url = `${VUE_APP_AUTHENTICATED_API_URL}readTickerFeed`;
      const headers = { headers: { Authorization: token.jwtToken } };

      await axios
        .get(url, headers)
        .then((response) => {
          let rawCoins = this.parseCoinData(response.data.data.coins);
          if (rawCoins.length > 0) {
            // Build marquee
            for (let i = 0; i < rawCoins.length; i++) {
              // Remove USDT
              rawCoins[i].symbol = rawCoins[i].symbol.slice(0, -4);
              const coin = rawCoins[i];
              const change = parseFloat(coin.priceChangePercent);
              // Neutral
              let color = "#e6e6e6";
              let arrow = "";
              // Green
              if (change > 0) {
                color = "#00B746";
                arrow = "▲";
              }
              // Red
              else if (change < 0) {
                color = "#EF403C";
                arrow = "▼";
              }

              const ticker = `<span style="color:${color}">${
                coin.symbol
              } ${change.toFixed(2)}% ${arrow}</span> &emsp;`;
              this.marquee.push(ticker);
            }
            this.coins = rawCoins;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.loading = false;
    },
    parseCoinData(coins) {
      const parsedCoins = [];
      for (let i = 0; i < coins.length; i++) {
        const coin = coins[i];
        const parsedCoin = {};
        parsedCoin.askPrice = parseFloat(coin.askPrice);
        parsedCoin.askQty = parseFloat(coin.askQty);
        parsedCoin.bidPrice = parseFloat(coin.bidPrice);
        parsedCoin.bidQty = parseFloat(coin.bidQty);
        parsedCoin.closeTime = coin.closeTime;
        parsedCoin.count = parseInt(coin.count);
        parsedCoin.firstId = coin.firstId;
        parsedCoin.highPrice = parseFloat(coin.highPrice.toString());
        parsedCoin.lastId = coin.lastId;
        parsedCoin.lastPrice = parseFloat(coin.lastPrice.toString());
        parsedCoin.lastQty = parseFloat(coin.lastQty);
        parsedCoin.lowPrice = parseFloat(coin.lowPrice.toString());
        parsedCoin.openPrice = parseFloat(coin.openPrice.toString());
        parsedCoin.openTime = coin.openTime;
        parsedCoin.prevClosePrice = parseFloat(coin.prevClosePrice);
        parsedCoin.priceChange = parseFloat(coin.priceChange);
        parsedCoin.priceChangePercent = parseFloat(coin.priceChangePercent);
        parsedCoin.quoteVolume = parseInt(coin.quoteVolume.toString());
        parsedCoin.symbol = coin.symbol;
        parsedCoin.volume = parseInt(coin.volume.toString());
        parsedCoin.weightedAvgPrice = parseFloat(coin.weightedAvgPrice);

        // Check if market cap is available
        const symbol = parsedCoin.symbol.substring(0, parsedCoin.symbol.length - 4).toLowerCase();
        if(this.marketCaps[symbol] !== undefined){
          const capData = this.marketCaps[symbol];
          parsedCoin.name = capData.full_name;
          parsedCoin.marketCap = parseInt(capData.market_cap);
          parsedCoin.marketCapRank = parseInt(capData.market_cap_rank);
          parsedCoin.fullyDilutedValuation = parseFloat(capData.fully_diluted_valuation);
          parsedCoin.marketCapChange24h = parseFloat(capData.market_cap_change_24h);
          parsedCoin.marketCapChangePercentage24h = parseFloat(capData.market_cap_change_percentage_24h);
          parsedCoin.circulatingSupply = parseInt(capData.circulating_supply);
          parsedCoin.totalSupply = parseInt(capData.total_supply);
          parsedCoin.maxSupply = parseInt(capData.max_supply);
          parsedCoin.lastUpdated = capData.last_updated;
          parsedCoin.image = capData.image;
        } else {
          continue;
        }

        parsedCoins.push(parsedCoin);
      }
      return parsedCoins;
    },
    on_trades(updates) {
      for (let i = 0; i < updates.length; i++) {
        const update = updates[i];
        for (let j = 0; j < this.coins.length; j++) {
          const coin = this.coins[j];
          // IMPORTANT - prevents non USD quotes from overriding table values
          if(update.s.substring(update.s.length - 4) !== 'USDT') {
            continue;
          }
          const symbol = update.s.slice(0, -4);
          if (coin.symbol === symbol) {
            // TODO: should use full ticker to update all fields
            // update propeties provided by the mini ticker ws
            this.coins[j].lastPrice = parseFloat(update.c.toString());
            this.coins[j].highPrice = parseFloat(update.h.toString());
            this.coins[j].lowPrice = parseFloat(update.l.toString());
            this.coins[j].openPrice = parseFloat(update.o.toString());
            this.coins[j].highPrice = parseFloat(update.h.toString());
            this.coins[j].quoteVolume = parseFloat(update.q.toString());
            this.coins[j].volume = parseFloat(update.v.toString());
          }
        }

        // s - symbol
        // E - event time
        // c - last price
        // e - event type
        // h - high price
        // l - low price
        // o - open price
        // q - total traded quote asset volume
        // v - total traded base asset volume
      }
    },
    async isBetaUser(user) {
      this.loading = true;
      this.loadingStatus = "Verifying alpha user...";
      const token = user.signInUserSession.idToken;
      const url = `${VUE_APP_AUTHENTICATED_API_URL}VerifyBetaUser`;
      const headers = { headers: { Authorization: token.jwtToken } };

      await axios
        .get(url, headers)
        .then((response) => {
          if(response.data.verified === true) {
            this.verifiedBetaUser = true;
          } else {
            this.verifiedBetaUser = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.verifiedBetaUser = false;
        });

      this.loading = false;
    },
    async hasAcceptedUserTerms(user) {
      this.loading = true;
      this.loadingStatus = "Verifying user terms...";
      const token = user.signInUserSession.idToken;
      const url = `${VUE_APP_AUTHENTICATED_API_URL}VerifyUserAgreement`;
      const headers = { headers: { Authorization: token.jwtToken } };

      await axios
        .get(url, headers)
        .then((response) => {
          if(response.data.verified === true) {
            this.acceptedUserTerms = true;
          } else {
            this.acceptedUserTerms = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.acceptedUserTerms = false;
        });

      this.loading = false;
    },
    async updateUserAgreementStatus(value) {
      this.acceptedUserTerms = value;
      if(this.acceptedUserTerms) {
        await this.initializeAppData();
      }
    },
    updatingLoadingStatus(value) {
      this.loading = value;
    },
    async initializeAppData() {
      this.loadingStatus = "Syncing historical market data...";
      // Pull market caps
      this.getMarketCapData(this.user);

      // Pull coin data once authed
      await this.getCoinData(this.user);
      // If coin initialization fails for any reason
      if(this.coins === undefined) {
        this.marquee = [];
        // Try again
        await this.getCoinData(this.user);

        // If still undefined, try reloading the app
        if(this.coins === undefined) {
          location.reload();
        }
      }

      // Pull user settings
      this.loadingStatus = "Loading user settings...";
      await this.loadUserSettings(this.user);

      this.loadingStatus = "Initializing data streams...";
      this.stream = new Stream(
        "wss://data-stream.binance.vision:9443/ws/!miniTicker@arr" // or !ticker@arr
      );
      this.stream.ontrades = this.on_trades;

      this.loadingStatus = "Loading Complete";
    },
    async loadUserSettings(user) {
      this.loading = true;
      try {
        this.firstName = user.attributes.given_name;
        const token = user.signInUserSession.idToken;
        const url = `${VUE_APP_AUTHENTICATED_API_URL}readUserSettings`;
        const headers = { headers: { Authorization: token.jwtToken } };

        await axios
          .get(url, headers)
          .then((response) => {
            if(response.status === 200) {
              // Load user settings
              this.userSettings = response.data.data.settings;
              // TODO: make sure entire schema is available or add defaults
            }
          })
          .catch(() => {
            // Load default user settings
            this.userSettings = userDefaults;
          });
      } catch (error) {
        this.userSettings = userDefaults;
        console.log(error);
      }
      this.loading = false;
    },
    async updateUserSettings(userSettings){
      const user = await Auth.currentAuthenticatedUser();
			const token = user.signInUserSession.idToken;
      const url = `${VUE_APP_AUTHENTICATED_API_URL}saveUserSettings`;
      const headers = { headers: { Authorization: token.jwtToken } };
			const body = JSON.stringify({
				settings: userSettings
			});

			await axios
        .post(url, body, headers)
        .then((response) => {
          if(response.status === 200) {
            this.userSettings = userSettings;
            return true;
          } else {
            // TODO: If failed notify user
            return true;
          }
        })
        .catch((error) => {
          console.log(error);
          return false;
        })
    }
  },
  created() {
    this.loadingStatus = 'Loading...';
    this.unsubscribeAuth = onAuthUIStateChange(async (authState, authData) => {
      this.authState = authState;
      this.user = authData;

      // Handle auth state changes
      switch (this.authState) {
        // User at sign in
        case "signin":
          this.loadingStatus = "Signed Out";
          break;
        // User signed in
        case "signedin":
          // Verify if user is enrolled in Alpha/Beta
          await this.isBetaUser(this.user);

          // Verify user has accepted the latest terms of use
          await this.hasAcceptedUserTerms(this.user);

          if(this.acceptedUserTerms === false){
            return;
          }

          await this.initializeAppData();
          break;
        default:
          break;
      }
    });
  },
  mounted() {},
  beforeDestroy() {
    this.unsubscribeAuth();
    if (this.stream) this.stream.off();
  },
};
</script>

<style scoped>
amplify-authenticator {
  --container-align: top;
  --container-height: 400px;
  --box-shadow: 0;
  margin-top: -25px !important;
}
#signout {
  padding-top: 10px;
  text-align: center;
  font-size: 14px;
  color: blue;
  cursor: pointer;
}
#nonBetaUser {
  text-align: justify;
  text-justify: inter-word;
  margin: auto;
  width: 300px;
}
#terms {
  max-width: 600px;
  margin: auto;
}
#loadingDiv {
  max-width: 600px;
  margin: auto;
}
.socialIcons {
  text-decoration: none !important;
  color: #e6e6e6;
}
#footer{
  padding-top: 25px;
}
.p-menubar {
  height: 45px;
}
.p-grid {
  margin: 0;
}
.p-col {
  padding: 0;
}
amplify-sign-in.hydrated {
  margin-top: -40px !important;
}
amplify-sign-up.hydrated {
  margin-top: -40px !important;
}
</style>
